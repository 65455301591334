.icons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  
}

.icon {
  width: 24px;
  margin: 12px;
}