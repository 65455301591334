:root{
  --dacsa-color-primary: #ffae00;
  --dacsa-color-secondary: #046a38;
  --dacsa-color-primary-opacity: #ffae0014;
  --dacsa-color-secondary-opacity: #046a3814;
}

body{
  font-family: Poppins, Roboto, sans-serif !important;
  color: #727475;
}

.title {
  font-size: 20px;
}

.container-fluid{
  width: 95%;
}

.linkTable{
  color: #727475 !important;
}

.linkTable:hover{
  color: var(--dacsa-color-primary) !important;
}


a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
  .navbar-main{
    background: white;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar-main {
  background: linear-gradient(to right, var(--dacsa-color-primary), var(--dacsa-color-secondary));
}

.navbar-nav {
  align-items: center;
  float: right;
} 

.btn-secondary {
  background-color: white !important;
  color: black;
}

.colorWhite {
  color: white !important;
}

.colorGrey {
  color: #727475 !important;
}

.colorDarkGrey {
  color:#83858e !important;
  font-weight: bold;
}

.navColorGrey .nav-link.active {
  color: #727475 !important;
}

.navColorGrey .nav-link {
  color: #727475 !important;
}

.navColorGrey .nav-link:hover {
  color: var(--dacsa-color-primary) !important;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  position: relative;
  height: 40px;
  border: 2px dashed #d0d7de;
  margin-left: 10px;
  margin-right: 10px;
}

.text-information {
  margin-top: 10px;
  text-align: center;
  font-size: 15px;
}

.formatComment {
  background-color: bisque;
  border: 4px solid #d0d7de;
  border-color: black;
}

hr {
  background-color: gray;
  height: 0.5px;
}

hr.createProject {
  background-color: gray;
  height: 0.5px;
}

.indent-1 {
  margin-left: 1%;
}

.indent-2 {
  margin-left: 2%;
}

.botonComment {
  background-color: gray !important;
  border-color: gray !important;
}

.botonComment:hover {
  background-color: var(--dacsa-color-primary) !important;
  border-color: var(--dacsa-color-primary) !important;
}

.dacsa-yellow {
  background-color: var(--dacsa-color-primary) !important;
}

.dacsa-green {
  background-color: var(--dacsa-color-secondary) !important;
}

.scrollComments {
  width: 100%;
  height: 250px;
  overflow: auto;
}

.separador {
  width: 1% !important;
}

.startApp p {
  margin-top: -23px;
  margin-left: -163px;
  left: 50%;
  top: 50%;
  position: absolute;
  vertical-align: middle;
}

.startApp {
  margin-top: -23px;
  margin-left: -163px;
  left: 50%;
  top: 50%;
  position: absolute;
  vertical-align: middle;
}

#footerDacsa {
  width: 100%;
  height: 20px;
  bottom: 0;
  left: 0;
  text-align: center;
  font-size: 9px;
  padding: 1em 0;
  margin-bottom: 1em;
}

.link {
  color: gray !important;
}

.link:hover {
  color: var(--dacsa-color-primary) !important;
}

.vr {
  width: 3px !important;
}

.width {
  display: inline-block;
  align-self: stretch;
  width: 3px;
  min-height: 1em;
  background-color: grey;
  opacity: .25;
}

.shadowGreen:hover {
  color: var(--dacsa-color-secondary) !important;
}

.shadowYellow:hover {
  color: var(--dacsa-color-primary) !important;
}

.navless-container {
  padding: 0 15px 65px;
  text-align: center;
}

.fontSize-18 {
  font-size: 18px;
}

.fontSize-20 {
  font-size: 20px;
}

.fixed {
  position:fixed !important;
  width: 95%;
  background-color: white;
  top:50px;
}

.botonNewProject {
  background-color: gray !important;
  border-color: gray !important;
  color: white !important;
  display: initial !important;
  padding: 0.5em !important;
  float: right;
}

.botonNewProject:hover {
  background-color: var(--dacsa-color-primary) !important;
  border-color: var(--dacsa-color-primary) !important;
}

.botonCancel {
  background-color: gray !important;
  border-color: gray !important;
  color: white !important;
  display: initial !important;
  padding: 0.4em !important;
}

.botonCancel:hover {
  background-color: var(--dacsa-color-primary) !important;
  border-color: var(--dacsa-color-primary) !important;
}

.textareaCreate {
  width: 98%;
}

.botonExcel {
  color: gray !important;
}

.botonExcel:hover {
  color: var(--dacsa-color-primary) !important;
}

#selfApproval.form-check-input:checked{
  background-color: var(--dacsa-color-primary) !important;
  border: var(--dacsa-color-primary);
  border-color: var(--dacsa-color-primary);
  appearance: none;
}

#requiresAttached.form-check-input:checked{
  background-color: var(--dacsa-color-primary) !important;
  border: var(--dacsa-color-primary);
  border-color: var(--dacsa-color-primary);
  appearance: none;
}

.checkIndent-1{
  width: 16.66666667%;
  padding: 1% 0 0 1%;
}

.checkIndent-2{
  width: 16.66666667%;
  padding: 1% 0 0 2%;
}

.investementTypeName {
  width: 70%;
}

.alignEnd {
  text-align : end;
}

.group {
  z-index: 2;
}

.css-lr4jrn-MuiPaper-root-MuiAlert-root {
  background-color: var(--dacsa-color-primary-opacity) !important;
}

#a.dropdown-item.hover {
  background-color: var(--dacsa-color-primary-opacity) !important;
}

.navbar-expand-sm .navbar-nav .nav-link {
  color: white !important;
}

a.dropdown-item.active {
  background-color: var(--dacsa-color-primary-opacity) !important;
  color:var(--dacsa-color-secondary);
}

a.dropdown-item:hover {
  background-color: var(--dacsa-color-primary-opacity) !important;
  color:var(--dacsa-color-secondary);
}
.botonTable {
  background-color: gray !important;
  border-color: gray !important;
  color: white !important;
}

.spinnerCenter {
  text-align: center;
}

.css-13cymwt-control:hover {
  border-color: var(--dacsa-color-primary) !important;
}

.css-t3ipsp-control:hover {
  border-color: var(--dacsa-color-primary) !important;
  box-shadow: var(--dacsa-color-primary) !important;
}

.css-t3ipsp-control {
  box-shadow: 0 0 0 1px var(--dacsa-color-primary) !important;
}

#align-row {
  align-items: center;
  text-align: right;
}

#align-row.right.row {
  text-align: left;
}

#align-col-end.width-delete {
  width: 5%;
}

.botonAcceptModal {
  background-color: gray !important;
  border-color: gray !important;
}

.botonAcceptModal:hover {
  background-color: var(--dacsa-color-primary) !important;
  border-color: var(--dacsa-color-primary) !important;

}

.navbar-expand-sm .navbar-nav .nav-link.active {
  color:var(--dacsa-color-secondary) !important;
}

.css-3fp33l-MuiTypography-root{
  max-width: min(1000vw, 7000px) !important;
}
